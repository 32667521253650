import React from 'react';
import './App.css';  // Import custom styles

function App() {
  return (
    <div className="App">
      <div className="container">
        <h1>Website Under Construction</h1>
        <p>We're working hard to get things up and running. Please check back later.</p>
        <div className="construction-image">
          {/* Optional: You can add an image if you want */}
          <img src="https://via.placeholder.com/200" alt="Under construction" />
        </div>
      </div>
    </div>
  );
}

export default App;
